import React, { useEffect, useRef, useState } from 'react';

import Dropzone from 'react-dropzone';
import { Video, Image, XSquare } from 'react-feather';
import './css/imageDnD.css';
import messages from '../../../helpers/messages';

import { graphPost } from '../../../api/expressApi';
import { SpinnerCircularFixed } from 'spinners-react';
import { useTranslation } from 'react-i18next';
import Hls from 'hls.js';

interface ImageDnDProps {
    label?: string;
    model: Record<string, any>; // should contain preview field and/or legacy field
    previewField?: string; // field will be used for preview
    legacyField?: string; // will be used for preview and clearing if no previewField is set
    uploadImageEndpoint?: string; // determines whether component accepts images
    uploadVideoEndpoint?: string; // determines whether component accepts videos
    imageProcessor?: (imageObj: File) => any; // only for images; video processing happens on server
    setFieldValue: any;
    setFieldError: any;
    fieldNames: string[];
    showPreview?: boolean;
}

const getFileExtension = (fileUrl: string | null): string => {
    if(!fileUrl)
        return '';

    const parts = fileUrl.split('.');

    if (parts.length > 1) {
        const lastPart = parts[parts.length - 1];
        const cleanExtension = lastPart.split(/\#|\?/)[0];

        if (cleanExtension) {
            return cleanExtension.toLowerCase();
        }
    }

    return '';
};

const getMediaUrl = (model: Record<string, any>, previewField: string | undefined, legacyField: string | undefined) => {
    let mediaUrl = null;

    if(model) {
        const previewFieldValue = !previewField ? null : getNestedValue(model, previewField);
        const legacyFieldValue = !legacyField ? null : getNestedValue(model, legacyField);

        if(previewFieldValue) {
            mediaUrl = previewFieldValue as string;
        } else if(legacyFieldValue) {
            mediaUrl = legacyFieldValue as string;
        }
    }

    return mediaUrl;
};

function getNestedValue<T>(obj: Record<string, any>, path: string): T | undefined {
    return path.split('.').reduce((acc: any, key: string) => acc && acc[key], obj);
}

const MediaDnD: React.FC<ImageDnDProps> = (props) => {

    const showPreview = typeof props.showPreview === "undefined" ? true : props.showPreview;

    const [state, setState] = useState({
        uploadingImage: false,
        uploadingVideo: false,
        errorMsg: ''
    });

    const { t } = useTranslation();

    const acceptsImages = !!props.uploadImageEndpoint;
    const acceptsVideos = !!props.uploadVideoEndpoint;

    const mediaUrl = getMediaUrl(props.model, props.previewField, props.legacyField);
    console.log(mediaUrl);
    const fileExtension = getFileExtension(mediaUrl);

    const imageExtensions = ['png', 'jpeg', 'jpg'];
    const videoExtensions = ['m3u8'];

    const mediaUrlIsImage = imageExtensions.includes(fileExtension);
    const mediaUrlIsVideo = videoExtensions.includes(fileExtension);

    const onDrop = async (mediaObj: File) => {

        const mimeType = mediaObj.type;

        if (acceptsImages && mimeType.startsWith('image/'))
            await uploadImage(mediaObj);
        else if (acceptsVideos && mimeType.startsWith('video/'))
            await uploadVideo(mediaObj);
        else
            setState({ ...state, errorMsg: 'This type of file is not allowed' });
    };

    const uploadImage = async (imageObj: File) => {

        setState({ ...state, uploadingImage: true });
        const formData = new FormData();

        if (props.imageProcessor) {
            const images = await props.imageProcessor(imageObj);

            for (const image of images) {
                formData.append('images', image);
            }
        } else {
            formData.append('images', imageObj);
        }

        try {

            const data = await graphPost(props.uploadImageEndpoint, formData);

            for (let i = 0; i < props.fieldNames.length; i++) {
                props.setFieldValue(props.fieldNames[i], data.imageUrls[i]);
            }

            setState({ ...state, errorMsg: '', uploadingImage: false });

        } catch (err) {
            setState({ ...state, errorMsg: (err as any).message, uploadingImage: false });
        }
    };

    const uploadVideo = async (videoObj: File) => {

        setState({ ...state, uploadingVideo: true });

        const formData = new FormData();
        formData.append('video', videoObj);

        try {
            const data = await graphPost(props.uploadVideoEndpoint, formData);

            props.setFieldValue(props.fieldNames[0], data.playlistUrl);
            setState({ ...state, errorMsg: '', uploadingVideo: false });
        } catch (err) {
            setState({ ...state, errorMsg: (err as any).message, uploadingVideo: false });
        }
    };

    function onMediaError(reason: string) {
        props.setFieldError(props.fieldNames[0], reason);
        setState({ ...state, errorMsg: reason });
    }

    const onClear: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
        ev.stopPropagation();

        for (let i = 0; i < props.fieldNames.length; i++) {
            props.setFieldValue(props.fieldNames[i], '');
        }

        if(props.legacyField) {
            props.setFieldValue(props.legacyField, '');
        }
    };

    const loadVideo = (url: string) => {

        let hls: Hls | undefined;

        if (url && videoRef.current) {
            if (Hls.isSupported()) {
                hls = new Hls() as Hls;
                hls.loadSource(url);
                hls.attachMedia(videoRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    if (videoRef.current)
                        videoRef.current.play().catch((err) => {
                            console.error("Error attempting to play:", err);
                        });
                });

                hls.on(Hls.Events.ERROR, (event, data) => {
                    if (hls && data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                console.error("Fatal network error encountered, trying to recover");
                                hls.startLoad();
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.error("Fatal media error encountered, trying to recover");
                                hls.recoverMediaError();
                                break;
                            default:
                                hls.destroy();
                                break;
                        }
                    }
                });
            } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                if (videoRef.current) {
                    videoRef.current.src = url;
                    videoRef.current.addEventListener('loadedmetadata', () => {
                        if (videoRef.current)
                            videoRef.current.play().catch((err) => {
                                console.error("Error attempting to play:", err);
                            });
                    });
                }
            }
        }
        
        return hls;
    };

    useEffect(() => {
        let hls: Hls | undefined;

        if(mediaUrl && mediaUrlIsVideo && videoRef.current) {
            hls = loadVideo(mediaUrl);
        }      
        
        return () => {
            if(hls)
                hls.destroy();
        };
    }, [mediaUrl]);

    const imageRef = useRef<HTMLImageElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    return (
        <div className='form-group'>
            {!props.label ? null :
                <label style={{
                    position: 'relative',
                    bottom: '-22px'
                }} className="control-label ">{props.label}</label>
            }
            <div className='control-field'>
                <div className='form-group'>
                    <div className="images-container">
                        <div className="image-dnd-container">
                            {!state.errorMsg ? null :
                                <p style={{ color: 'red' }}>
                                    {state.errorMsg}
                                </p>
                            }
                            <Dropzone
                                className={`imageWrapper imageDnD uitest-dropzone`}
                                multiple={false}
                                //accept="image/*"
                                onDrop={(acceptedFiles: File[]) => onDrop(acceptedFiles[0])}
                                onDropRejected={() => onMediaError(messages.errors.wrongType)}
                            >
                                {(state.uploadingImage || state.uploadingVideo) ?
                                    <>
                                        <SpinnerCircularFixed
                                            speed={400}
                                            color={'#bbb'}
                                            secondaryColor={'#eee'}
                                            thickness={100}
                                            size={50}
                                            style={{
                                                width: '50px',
                                                top: '50%',
                                                left: '50%',
                                                position: 'absolute',
                                                transform: 'translate(-50%, -50%)'
                                            }}
                                        />
                                        <p style={{ marginTop: '20px' }}>
                                            Качване на {state.uploadingImage ? 'изображение' : state.uploadingVideo ? 'видео' : ''}...
                                        </p>
                                    </> :
                                    <>
                                        {!mediaUrl ? null : (
                                            <button type="button" className="btn transparent clearButton uitest-clearbutton" onClick={onClear}>
                                                <XSquare className="icon" />
                                            </button>
                                        )}
                                        <div>
                                            {!acceptsImages ? null : <Image className="icon" style={{ display: 'inline-block' }} />}
                                            {!acceptsVideos ? null : <Video className="icon" style={{ display: 'inline-block' }} />}
                                            <span style={{ display: 'block' }}>
                                                {(acceptsImages && !acceptsVideos) ? 'ПУСНЕТЕ ИЗОБРАЖЕНИЕ' : null}
                                                {(!acceptsImages && acceptsVideos) ? 'ПУСНЕТЕ ВИДЕО' : null}
                                                {(acceptsImages && acceptsVideos) ? 'ПУСНЕТЕ ВИДЕО/ИЗОБРАЖЕНИЕ' : null}
                                            </span>
                                            {t('label_video_dnd_or')} <button type="button">{t('label_video_dnd_browse')}</button>
                                        </div>
                                    </>
                                }
                                {mediaUrl && <img src={mediaUrl} alt="" />}
                            </Dropzone>
                            <p>{t('label_video_dnd_maximum_size')} 5 MB</p>
                        </div>
                        {!showPreview ? null :
                            <div className="image-preview">
                                <div className="imageWrapper" style={{ display: 'flex' }}>
                                    {!mediaUrl ? null :
                                        mediaUrlIsImage ?
                                            <img ref={imageRef} src={mediaUrl} /> :
                                        mediaUrlIsVideo ?
                                            <video ref={videoRef} style={{ width: '100%', height: '100%', objectFit: 'contain' }} autoPlay controls muted loop /> :
                                            null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MediaDnD;